/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import "./layout.css"

const Layout = ({ children }) => {


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sunday Strolls</title>
        <meta property="og:url" content="https://strolls.brickriverlab.com" />
        <meta property="og:type" content="blog" />
        <meta property="og:title" content="Sunday Strolls" />
        <meta property="og:description" content="Weekly walks during the pandemic" />
        <meta property="og:image" content="https://images.ctfassets.net/59ekycdxxep4/1H2OFkPqYE6NTaCgylR4tq/2ae79d15fc5f3c45f876c2037c46415e/feat.jpg?w=800&q=50" />
        <link rel="canonical" href="https://strolls.brickriverlab.com" />
      </Helmet>
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
